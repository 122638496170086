import { SearchOutlined } from '@ant-design/icons'
import { Input, Spin, Tag, Tooltip } from 'antd'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
/* eslint-disable no-restricted-imports */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TabContext from '@material-ui/lab/TabContext'
import TabPanel from '@material-ui/lab/TabPanel'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  showErrorMessage,
  showSuccessMessageIcon,
} from '../../actions/notification'
import makeRequest from '../../libs/request'
import Loading from '../loading'
// import { isValidPhoneNumber } from "react-phone-number-input";
import checkPermission from '../../libs/permission'

import HeaderBreadcrumb from '../../common/antd/component/HeaderBreadcrumb'
import ModalConfirm from '../../common/components/modal/ModalConfirm'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}))

const RenderStatusText = ({ row, onReload }) => {
  const [status, setStatus] = useState(row.status)
  const [show, setShow] = useState(false)

  const clickModalCancel = () => {
    setStatus(row.status)
    setShow(false)
  }

  const clickModalOk = () => {
    setShow(false)
    makeRequest('put', `icpa/agent-transactions/update-status/${row?._id}`, {
      status: status,
    })
      .then(({ data }) => {
        if (data.signal) {
          onReload()
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <select
        className={`form-control form-control-sm f-regular_18 mr-4 border-0 bg-light`}
        onChange={(e) => {
          let value = e.target.value
          setStatus(value)
          setShow(true)
        }}
        value={status}
        style={{
          color: status === 'pending' ? '#F0AD02' : '#62AC03'
        }}
      >
        <option
          value={'pending'}
          style={{
            color: '#F0AD02'
          }}
        >
          Pending
        </option>
        <option
          value={'done'}
          style={{
            color: '#62AC03'
          }}
        >
          Done
        </option>
      </select>
      <ModalConfirm
        title="Change status"
        visible={show}
        onConfirm={clickModalOk}
        onCancel={clickModalCancel}
        cancelText="Cancel"
        okText="Ok"
        content={
          <p className="f-regular_20">
            Are you sure you want to change status?
          </p>
        }
      />
    </>
  )
}
export default function ListICPA(props) {
  // Example 1
  const classes1 = useStyles1()
  let query = useQuery()
  const [page, setPage] = React.useState(0)
  const [size, setSize] = React.useState(10)
  const [totalRows, setTotalRows] = React.useState(0)
  const [rows, setRow] = useState([])
  const [dataAdd, setData] = useState({
    value: '',
  })
  const [dataSearch, setDataSearch] = useState({
    loadingPage: true,
    loadingTable: false,
  })
  const [activeKey, setActiveKey] = useState('1')
  const permissions = {
    view: 'tse.view',
    create: 'tse.create',
    update: 'tse.update',
    delete: 'tse.delete',
  }
  const [dataDelete, setDataDelete] = useState({ visible: false })
  const getData = (page = 0, perPage, keyword, status, approve) => {
    let value = page + 1
    makeRequest('get', `icpa/agent-transactions/get-all`, {
      page: value,
      perPage,
      keyword,
    })
      .then(({ data }) => {
        if (data.signal) {
          setRow(data.data.items ?? [])
          setTotalRows(data.data.total)
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (checkPermission(permissions.view)) {
      let key = activeKey
      if (query.get('activeTab')) {
        key = query.get('activeTab')
        setActiveKey(query.get('activeTab'))
      }
      getData(page, size, '', 'all', key)
      onChangeValue('loadingPage', false)
    } else {
      onChangeValue('loadingPage', false)
      return showErrorMessage('No Permission.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = (event, newPage) => {
    getData(newPage, size, dataSearch.keyword, dataSearch.status, activeKey)
    setPage(newPage)
  }

  const handleChangeSize = (event) => {
    setSize(parseInt(event.target.value, 10))
    setPage(0)
    getData(
      0,
      parseInt(event.target.value, 10),
      dataSearch.keyword,
      dataSearch.status,
      activeKey
    )
  }

  const onChangeValue = (key, value) => {
    setDataSearch({
      ...dataSearch,
      [key]: value,
    })
    setPage(0)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setPage(0)
    getData(0, size, dataSearch.keyword, dataSearch.status, activeKey)
  }

  if (dataSearch.loadingPage) {
    return <Loading />
  }

  return (
    <>
      <HeaderBreadcrumb
        breadcrumb={[
          {
            title: 'ICPA Transaction',
            link: '',
          },
        ]}
      />
      <div className='row pt-20'>
        <div className='col-md-12'>
          <div className='kt-section'>
            <div className='kt-section__content'>
              <Paper className={classes1.root}>
                <div>
                  <TabContext value={activeKey}>
                    <div className='search-form search-form-2'>
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          height: 'auto',
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={8} md={8} className='d-flex'>
                            <div className='f-regular_18'>

                            </div>
                          </Grid>
                          <Grid item xs={4} md={4}>
                            <Input
                              size='large'
                              onChange={(e) =>
                                onChangeValue('keyword', e.target.value)
                              }
                              onPressEnter={handleSubmit}
                              className='inline-block rounded border-color-grey border-radius-8'
                              placeholder='Search by keyword'
                              name='keyword'
                              value={dataSearch.keyword || ''}
                              prefix={
                                <SearchOutlined style={{ color: '#99A4C0' }} />
                              }
                            />
                          </Grid>
                        </Grid>
                      </Form>
                    </div>
                    <div
                      style={{
                        overflow: 'hidden',
                        overflowX: 'auto',
                        overflowY: 'auto',
                        width: '100%'
                      }}
                    >
                      <TabPanel value='1' className='p-0'>
                        <Table className={classes1.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className='pl-16 f-semibold_20'
                                style={{
                                  minWidth: 125
                                }}
                              >
                                Referral code
                              </TableCell>
                              <TableCell className='f-semibold_20'
                                style={{
                                  minWidth: 125
                                }}
                              >
                                Selling price
                              </TableCell>
                              <TableCell className='f-semibold_20'
                                style={{
                                  minWidth: 125
                                }}
                              >
                                Discount
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                              >
                                Tax
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                                style={{
                                  minWidth: 125
                                }}
                              >
                                Stripe fee
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                                style={{
                                  minWidth: 200
                                }}
                              >
                                Commission percent
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                                style={{
                                  minWidth: 150
                                }}
                              >
                                Commission ($)
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                                style={{
                                  minWidth: 150
                                }}
                              >
                                Assign agent
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                                style={{
                                  minWidth: 200
                                }}
                              >
                                Remaining redemption
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                                style={{
                                  minWidth: 125
                                }}
                              >
                                Status
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                              >
                                Invoice
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataSearch.loadingTable ? (
                              <TableRow>
                                <TableCell colSpan={11} align='center'>
                                  <Spin tip='Loading...' />
                                </TableCell>
                              </TableRow>
                            ) : rows.length ? (
                              rows.map((row) => (
                                <TableRow key={row._id}>
                                  <TableCell className='pl-16'>
                                    {row?.referral_code ?? '--'}
                                  </TableCell>
                                  <TableCell>
                                    {row?.selling_price ? `${row?.selling_price.toFixed(3)}$` : '--'}
                                  </TableCell>
                                  <TableCell>{row?.discount && row?.discount?.discount_value ? row?.discount?.type === "fixed" ? `${row?.discount?.discount_value}$` : `${row?.discount?.discount_value}%` : '--'}</TableCell>
                                  <TableCell>
                                    {row?.tax
                                      ? `${row.tax}%`
                                      : '--'}
                                  </TableCell>
                                  <TableCell>
                                    {row?.stripe_fee
                                      ? `${row.stripe_fee.toFixed(3)}$`
                                      : '--'}
                                  </TableCell>
                                  <TableCell>
                                    {row.commission_percent
                                      ? `${row.commission_percent}%`
                                      : '--'}
                                  </TableCell>
                                  <TableCell>
                                    {row?.commission
                                      ? `${row.commission.toFixed(3)}$`
                                      : '--'}
                                  </TableCell>
                                  <TableCell>
                                    {row?.assign_agent
                                      ? row.assign_agent
                                      : '--'}
                                  </TableCell>
                                  <TableCell>
                                    {row?.remaining_redemption
                                      ? row.remaining_redemption
                                      : '--'}
                                  </TableCell>
                                  <TableCell>
                                    <RenderStatusText row={row} onReload={() => {
                                      getData(page, size, '', 'all', '')
                                    }} />
                                  </TableCell>
                                  <TableCell
                                    align='right'
                                    className='pr-16 text-fs-20'
                                  >
                                    {row?.invoice_pdf && (
                                      <a
                                        href={row.invoice_pdf}
                                        className='hover-span-action'
                                        data-toggle='tooltip'
                                        data-placement='top'
                                        title={'Invoice'}
                                        target="_blank"
                                      >
                                        <img
                                          style={{
                                            width: '20px',
                                            height: 'auto',
                                          }}
                                          src={'/images/button-icon/document-download.svg'}
                                          alt={'Invoice'}
                                        />
                                      </a>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={11} align='center'>
                                  <FormattedMessage id='TABLE.CELL.NO_DATA_TO_DISPLAY' />
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                        {Number(totalRows) > Number(size) && (
                          <TablePagination
                            className='table-pagination-customer'
                            labelRowsPerPage={`Page ${Number(page) +
                              1} of ${Math.ceil(
                                Number(totalRows) / Number(size)
                              )}`}
                            labelDisplayedRows={({ from, to, count }) => {
                              return ''
                            }}
                            rowsPerPageOptions={[10, 20, 50]}
                            component='div'
                            count={totalRows}
                            rowsPerPage={size}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeSize}
                          />
                        )}
                      </TabPanel>
                    </div>
                  </TabContext>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
