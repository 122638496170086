import { SearchOutlined } from '@ant-design/icons'
import { Input, Spin, Tag, Tooltip } from 'antd'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
/* eslint-disable no-restricted-imports */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TabContext from '@material-ui/lab/TabContext'
import TabPanel from '@material-ui/lab/TabPanel'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  showErrorMessage,
  showSuccessMessageIcon,
} from '../../actions/notification'
import makeRequest from '../../libs/request'
import Loading from '../loading'
// import { isValidPhoneNumber } from "react-phone-number-input";
import checkPermission from '../../libs/permission'

import HeaderBreadcrumb from '../../common/antd/component/HeaderBreadcrumb'
import ModalConfirm from '../../common/components/modal/ModalConfirm'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}))

export default function ListICPA(props) {
  // Example 1
  const classes1 = useStyles1()
  let query = useQuery()
  const [page, setPage] = React.useState(0)
  const [size, setSize] = React.useState(10)
  const [totalRows, setTotalRows] = React.useState(0)
  const [rows, setRow] = useState([])
  const [dataAdd, setData] = useState({
    value: '',
  })
  const [dataSearch, setDataSearch] = useState({
    loadingPage: true,
    loadingTable: false,
  })
  const [activeKey, setActiveKey] = useState('1')
  const permissions = {
    view: 'tse.view',
    create: 'tse.create',
    update: 'tse.update',
    delete: 'tse.delete',
  }
  const [dataDelete, setDataDelete] = useState({ visible: false })
  const getData = (page = 0, perPage, keyword, status, approve) => {
    let value = page + 1
    makeRequest('get', `icpa/accounts/get-list`, {
      page: value,
      perPage,
      keyword,
    })
      .then(({ data }) => {
        if (data.signal) {
          setRow(data.data.items ?? [])
          setTotalRows(data.data.total)
        } else {
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const showModal = (idDel) => {
    setDataDelete({
      ...dataDelete,
      visible: true,
      idDel,
    })
  }

  useEffect(() => {
    if (checkPermission(permissions.view)) {
      let key = activeKey
      if (query.get('activeTab')) {
        key = query.get('activeTab')
        setActiveKey(query.get('activeTab'))
      }
      getData(page, size, '', 'all', key)
      onChangeValue('loadingPage', false)
    } else {
      onChangeValue('loadingPage', false)
      return showErrorMessage('No Permission.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = (event, newPage) => {
    getData(newPage, size, dataSearch.keyword, dataSearch.status, activeKey)
    setPage(newPage)
  }

  const handleChangeSize = (event) => {
    setSize(parseInt(event.target.value, 10))
    setPage(0)
    getData(
      0,
      parseInt(event.target.value, 10),
      dataSearch.keyword,
      dataSearch.status,
      activeKey
    )
  }

  const onChangeValue = (key, value) => {
    setDataSearch({
      ...dataSearch,
      [key]: value,
    })
    setPage(0)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setPage(0)
    getData(0, size, dataSearch.keyword, dataSearch.status, activeKey)
  }

  const renderStatusText = (row) => {
    if (row.last_invoice === 'Unpaid') {
      return (
        <Tag bordered={false} className='tag-new'>
          Unpaid
        </Tag>
      )
    } else if (row.last_invoice === 'Free') {
      return (
        <Tag bordered={false} color='orange' className='tag-processing'>
          Free
        </Tag>
      )
    } else if (row.last_invoice === 'paid') {
      return (
        <Tag bordered={false} className='tag-marking-progress-2'>
          Paid
        </Tag>
      )
    } else
      return (
        <Tag bordered={false} className='tag-draft'>
          {row.last_invoice}
        </Tag>
      )
  }

  const renderList = (value) => {
    if (value) {
      let string = ''
      let valueDraft = value.map((i, index) => {
        if (index > 0) {
          string += `, ${i.company_name}`
        } else {
          string = `${i.company_name}`
        }
      })
      return (
        <Tooltip
          placement='bottomLeft'
          title={<div className='f-regular_20'>{string}</div>}
          color={'#ffffff'}
        >
          <div className='limit-text-2'>{string}</div>
        </Tooltip>
      )
    }
    return '--'
  }
  const renderPromo = (value) => {
    if (value) {
      let string = ''
      let valueDraft = value.map((i, index) => {
        if (index > 0) {
          string += `, ${i}`
        } else {
          string = `${i}`
        }
      })
      return (
        <Tooltip
          placement='bottomLeft'
          title={<div className='f-regular_20'>{string}</div>}
          color={'#ffffff'}
        >
          <div className='limit-text-2'>{string}</div>
        </Tooltip>
      )
    }
    return '--'
  }

  if (dataSearch.loadingPage) {
    return <Loading />
  }

  const clickModalCancel = () => {
    setData({
      value: '',
    })
    setDataDelete({
      ...dataDelete,
      visible: false,
      idDel: 0,
      err_value: false,
    })
  }

  const clickModalOk = () => {
    let { idDel } = dataDelete
    if (dataAdd?.value !== 'delete') {
      setData({
        ...dataAdd,
        err_value: true,
      })
      return showErrorMessage('Please enter delete.')
    }
    makeRequest('delete', `icpa/accounts/delete/${idDel}`)
      .then(({ data }) => {
        if (data.signal) {
          showSuccessMessageIcon('Deleted successfully')
          setData({
            value: '',
          })
          setDataDelete({
            ...dataDelete,
            visible: false,
            idDel: 0,
          })
          getData(page, size, dataSearch.keyword, dataSearch.status, activeKey)
        } else {
          return showErrorMessage('Error', data.message)
        }
      })

      .catch((err) => {
        console.log('error', err)
      })
  }

  return (
    <>
      <HeaderBreadcrumb
        breadcrumb={[
          {
            title: 'ICPA Management',
            link: '',
          },
        ]}
      />
      <div className='row pt-20'>
        <div className='col-md-12'>
          <div className='kt-section'>
            <div className='kt-section__content'>
              <Paper className={classes1.root}>
                <div>
                  <TabContext value={activeKey}>
                    <div className='search-form search-form-2'>
                      <Form
                        onSubmit={handleSubmit}
                        style={{
                          height: 'auto',
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={8} md={8} className='d-flex'>
                            <div className='f-regular_18'>
                              This is the page to sky view all information under
                              Account IDs. To access their Company(s) info as
                              Customer Services Support upon Clients' request,
                              please click the buttons under "View / Del" <br />
                              E.g., To update "KEY Email or info" of Owner(s),
                              Delete due to ops cease or bills viewing for
                              payment & storage usage.
                            </div>
                          </Grid>
                          <Grid item xs={4} md={4}>
                            <Input
                              size='large'
                              onChange={(e) =>
                                onChangeValue('keyword', e.target.value)
                              }
                              onPressEnter={handleSubmit}
                              className='inline-block rounded border-color-grey border-radius-8'
                              placeholder='Search by keyword'
                              name='keyword'
                              value={dataSearch.keyword || ''}
                              prefix={
                                <SearchOutlined style={{ color: '#99A4C0' }} />
                              }
                            />
                          </Grid>
                        </Grid>
                      </Form>
                    </div>
                    <div>
                      <TabPanel value='1' className='p-0'>
                        <Table className={classes1.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell className='pl-16 f-semibold_20'>
                                Account ID
                              </TableCell>
                              <TableCell className='f-semibold_20'>
                                List of Company(s)
                              </TableCell>
                              <TableCell className='f-semibold_20'>
                                Owner(s) Key Email
                              </TableCell>
                              <TableCell
                                className='f-semibold_20'
                                minWidth='200px'
                              >
                                Current Plan
                              </TableCell>
                              {/* <TableCell className='f-semibold_20'>
                                Agent/ PromoCode
                              </TableCell> */}
                              <TableCell
                                className='f-semibold_20'
                                minWidth='200px'
                              >
                                Last Invoice
                              </TableCell>
                              <TableCell
                                align='right'
                                className='f-semibold_20 pr-16 w-200'
                              >
                                View / Del
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dataSearch.loadingTable ? (
                              <TableRow>
                                <TableCell colSpan={7} align='center'>
                                  <Spin tip='Loading...' />
                                </TableCell>
                              </TableRow>
                            ) : rows.length ? (
                              rows.map((row) => (
                                <TableRow key={row._id}>
                                  <TableCell className='pl-16'>
                                    {row?.identifier ?? '--'}
                                  </TableCell>
                                  <TableCell>
                                    {renderList(row?.list_of_company)}
                                  </TableCell>
                                  <TableCell>{row.email}</TableCell>
                                  <TableCell>
                                    {row.current_plan
                                      ? row.current_plan.join(', ')
                                      : '--'}
                                  </TableCell>
                                  {/* <TableCell>
                                    {row.promo_code
                                      ? renderPromo(row?.promo_code)
                                      : '--'}
                                  </TableCell> */}
                                  <TableCell>{renderStatusText(row)}</TableCell>
                                  <TableCell
                                    align='right'
                                    className='pr-16 w-200 text-fs-20'
                                  >
                                    <Link
                                      to={`/icpa/edit/${row._id}`}
                                      className='hover-span-action'
                                      data-toggle='tooltip'
                                      data-placement='top'
                                      title={
                                        checkPermission(permissions.update)
                                          ? 'Edit'
                                          : 'Detail'
                                      }
                                    >
                                      <img
                                        style={{
                                          width: '20px',
                                          height: 'auto',
                                        }}
                                        src={'/images/button-icon/review.svg'}
                                        alt={'Detail'}
                                      />
                                    </Link>

                                    <span
                                      className={`hover-span-action-remove ${row._id ===
                                        1 && 'disabled-span'}`}
                                      style={{ cursor: 'pointer' }}
                                      onClick={(e) => {
                                        showModal(row._id)
                                      }}
                                      data-toggle='tooltip'
                                      data-placement='top'
                                      title='Delete Agent'
                                    >
                                      {checkPermission(permissions.update) &&
                                        ' | '}{' '}
                                      <img
                                        style={{
                                          width: '20px',
                                          height: 'auto',
                                        }}
                                        src={'/images/button-icon/delete.svg'}
                                        alt={'Remove'}
                                      />
                                    </span>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={7} align='center'>
                                  <FormattedMessage id='TABLE.CELL.NO_DATA_TO_DISPLAY' />
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                        {Number(totalRows) > Number(size) && (
                          <TablePagination
                            className='table-pagination-customer'
                            labelRowsPerPage={`Page ${Number(page) +
                              1} of ${Math.ceil(
                              Number(totalRows) / Number(size)
                            )}`}
                            labelDisplayedRows={({ from, to, count }) => {
                              return ''
                            }}
                            rowsPerPageOptions={[10, 20, 50]}
                            component='div'
                            count={totalRows}
                            rowsPerPage={size}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeSize}
                          />
                        )}
                      </TabPanel>
                    </div>
                  </TabContext>
                </div>
              </Paper>
              <ModalConfirm
                title='Delete Account'
                content={
                  <>
                    <p className='f-regular_20'>
                      Delete Account will result in all data of the company(s)
                      lost forever. Please check, if the Business have ceased or
                      if it a official request from the client? Foul play will
                      arise investigation. Please enter{' '}
                      <span style={{ fontWeight: 600 }}>"delete"</span> to
                      confirm the action.
                    </p>
                    <Form.Control
                      type='text'
                      maxLength={255}
                      className={dataAdd.err_value && 'has-error'}
                      placeholder='Enter delete'
                      value={dataAdd.value || ''}
                      onChange={(e) => {
                        setData({
                          ...dataAdd,
                          value: e.target.value,
                          err_value: false,
                        })
                      }}
                    />
                  </>
                }
                visible={dataDelete.visible}
                onCancel={clickModalCancel}
                onConfirm={clickModalOk}
                okText={'Confirm'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
