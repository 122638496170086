/* eslint-disable no-restricted-imports */
import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import 'react-phone-number-input/style.css'
import { showErrorMessage } from '../../actions/notification'
import makeRequest from '../../libs/request'
import Loading from '../loading'
import HeaderBreadcrumb from '../../common/antd/component/HeaderBreadcrumb'
import checkPermission from '../../libs/permission'
import TabICPA from './TabICPA'
import { useLocation } from 'react-router-dom'
import OverviewComponent from './components/OverviewComponent'
import HistoryComponent from './components/HistoryComponent'
import YourPublicReportComponent from './components/YourPublicReportComponent'
import YourCompanyComponent from './components/YourCompanyComponent'
import BillingComponent from './components/BillingComponent'
import NoDataComponent from './components/NoDataComponent'

export default function EditICPA(props) {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  // Example 1
  const [loading, setLoading] = useState({
    loadingProfile: false,
    loadingPage: true,
    loadingImage: false,
    loadingCertificate: false,
  })
  let [dataAdd, setData] = useState({})
  let [companyList, setCompanyList] = useState([])
  const { id } = props.match.params
  let [tab, setTab] = useState()
  const permissions = {
    update: 'tse.update',
    view: 'tse.view',
  }
  let idCompany = searchParams.get('idCompany')
  let tabDraft = searchParams.get('tab')

  const onSetLoading = (key, value) => {
    setLoading({
      ...loading,
      [key]: value,
    })
  }

  const getICPAListById = ({ idCompany, tabDraft, id }) => {
    makeRequest('get', `icpa/companies/get-all/${id}`)
      .then(({ data }) => {
        if (data.signal) {
          let itemList = data.data
          let companyList = itemList?.items.map((i) => {
            return {
              id: i._id,
              status: i.status,
              name: i.company_name,
              is_base: i.is_base,
            }
          })
          let selecCompany = itemList?.items[0]
          if (idCompany) {
            selecCompany = itemList?.items.find((i) => i._id === idCompany)
          }
          if (tabDraft) {
            setTab(tabDraft)
          } else {
            setTab('overview')
          }
          setCompanyList(companyList ?? [])
          setData(selecCompany)
          onSetLoading('loadingPage', false)
        } else {
          onSetLoading('loadingPage', false)
          return showErrorMessage(data.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (checkPermission(permissions.view)) {
      getICPAListById({ idCompany, tabDraft, id })
    } else {
      return showErrorMessage('No Permission.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCompany, tabDraft, id])

  if (loading.loadingPage) {
    return <Loading />
  }
  let breadcrumb = [
    {
      title: 'ICPA Management',
      link: '/icpa/list',
    },
  ]
  if (dataAdd?.company_name) {
    breadcrumb = [
      ...breadcrumb,
      {
        title: dataAdd?.company_name,
        link: '',
      },
    ]
  }
  return (
    <>
      <HeaderBreadcrumb breadcrumb={breadcrumb} />
      {companyList?.length > 0 && (
        <div className='row pt-40 tab-cb-customer'>
          <TabICPA
            idValue={dataAdd?._id}
            idICPA={id}
            listTab={companyList}
            {...props}
          />
        </div>
      )}

      {companyList?.length > 0 ? (
        <Form>
          <div className='row'>
            <div className='col-md-12'>
              <div className='kt-section'>
                <div className='kt-section__content'>
                  <div className='col-md-12'>
                    <Tabs
                      className='ant-tabs-nav-list-5'
                      onChange={(value) => {
                        setTab(value)
                        props.history.push(
                          `/icpa/edit/${id}?idCompany=${dataAdd?._id}&tab=${value}`
                        )
                      }}
                      activeKey={tab}
                    >
                      <Tabs.TabPane tab='Overview' key='overview'>
                        <OverviewComponent
                          value={dataAdd}
                          id={dataAdd?._id}
                          show={tab === 'overview'}
                          tab={tab}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab='History' key='history'>
                        <HistoryComponent
                          value={dataAdd}
                          id={id}
                          show={tab === 'history'}
                          tab={tab}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab='Your company' key='your-company'>
                        <YourCompanyComponent
                          value={dataAdd}
                          id={dataAdd?._id}
                          show={tab === 'your-company'}
                          tab={tab}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        tab='Your Public Report'
                        key='your-public-report'
                      >
                        <YourPublicReportComponent
                          value={dataAdd}
                          id={dataAdd?._id}
                          show={tab === 'your-public-report'}
                          tab={tab}
                        />
                      </Tabs.TabPane>
                      <Tabs.TabPane tab='Billing' key='billing'>
                        <BillingComponent
                          value={dataAdd}
                          plan={dataAdd?.plan}
                          id={dataAdd?._id}
                          show={tab === 'billing'}
                          tab={tab}
                        />
                      </Tabs.TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      ) : (
        <NoDataComponent paddingTop={'50px'} />
      )}
    </>
  )
}
