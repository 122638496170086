import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { REMINDER_STATUS } from './CurrentYearComponent'

const genArrayReminders = (data, index, length, year) => {
  let list = []
  if (data) {
    data.map((i) => {
      let newData = Array(length).fill(0)
      newData[index] = 1
      list.push({
        name: `${i.name} - ${year}`,
        data: newData,
        id: i._id,
        status: i.status,
        year: year,
      })
    })
    return list
  }
  return []
}

const SummaryComponent = ({ selectData, certification = {}, changeSelect }) => {
  const [seriesData, setSeries] = useState([])
  const [categories, setCategories] = useState([])

  const getValueReminders = (details = []) => {
    let seriesList = []
    let categoryList = []
    if (details) {
      details.map((item, index) => {
        categoryList.push(moment(item?.valid_from, 'DD/MM/YYYY').year())
      })

      details.map((item, index) => {
        let newList = genArrayReminders(
          item?.reminders,
          index,
          categoryList?.length,
          moment(item?.valid_from, 'DD/MM/YYYY').year()
        )
        seriesList.push(...newList)
      })
    }
    setCategories(categoryList)
    setSeries(seriesList)
  }

  useEffect(() => {
    if (certification) {
      getValueReminders(certification.details)
    }
  }, [certification])

  let state = {
    series: seriesData,
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
        events: {
          click: function(e, chartContext, { seriesIndex }) {
            const data = seriesData[seriesIndex]
            changeSelect(data?.year)
          },
          mounted: (chart) => {
            chart.windowResizeHandler()
          },
        },
      },
      colors: [
        '#FF443C',
        '#FF8800',
        '#FFCA41',
        '#A1DC67',
        '#02C038',
        '#29B9E6',
        '#338FEB',
        '#003AA5',
        '#9747FF',
        '#FF0492',
      ],
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: '#338FEB',
            fontSize: '14px',
            fontWeight: 600,
            fontFamily: 'Poppins, sans-serif',
            cssClass: 'box-tab',
          },
        },
        axisTicks: { show: false },
        axisBorder: {
          borderRadius: 8,
          color: '#C0C7D8',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        show: false,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const isLast = series[seriesIndex]?.length - 1 === dataPointIndex
          const data = seriesData[seriesIndex]
          const isFist = dataPointIndex === 0
          const isMissed = data?.status === REMINDER_STATUS.MISSED
          const isSnoozed = data?.status === REMINDER_STATUS.SNOOZED
          const isAcknowledged = data?.status === REMINDER_STATUS.ACKNOWLEDGED
          return (
            `<div class="arrow_box ${isMissed ? 'arrow_box_missed' : ''} ${
              isSnoozed ? 'arrow_box_snoozed' : ''
            } ${isAcknowledged ? 'arrow_box_acknowledged' : ''} ${
              isLast ? 'arrow_box_last' : 'arrow_box_not_last'
            } ${isFist ? 'arrow_box_fist' : ''} ${
              !isFist && !isLast ? 'arrow_box_normal' : ''
            }">` +
            '<div class="arrow_title">' +
            (isMissed
              ? 'Missed'
              : isSnoozed
              ? 'Snoozed'
              : isAcknowledged
              ? 'Acknowledged'
              : '') +
            '</div>' +
            '<div class="arrow_body">' +
            '<div class="arrow_dot_1">' +
            '</div>' +
            '<div class="arrow_line">' +
            '</div>' +
            '<div class="arrow_dot">' +
            '</div>' +
            '</div>' +
            '</div>'
          )
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '58px',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
        padding: {
          top: -16,
          bottom: 32,
          left: -16,
          right: -16,
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        markers: {
          shape: 'circle',
        },
        fontSize: '14px',
        fontWeight: 400,
        borderRadius: '8px',
        labels: {
          colors: '#1F263E',
          useSeriesColors: false,
          borderRadius: '8px',
        },
      },
    },
  }

  const width = categories?.length > 10 ? categories?.length * 58 : '100%'

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: '-35px',
          right: '0px',
        }}
        className='rowy-center-end'
      >
        {certification?.no_of_years_per_cycle && (
          <div
            style={{
              background: '#F4F7FE',
              border: '1px solid #1F263E',
              padding: '6px 12px',
              borderRadius: '6px',
            }}
            className='f-medium_20 mr-10'
          >
            {certification?.no_of_years_per_cycle} years per cycle
          </div>
        )}
        {selectData?.valid_from && (
          <div
            style={{
              background: '#F4F7FE',
              border: '1px solid #1F263E',
              padding: '6px 12px',
              borderRadius: '6px',
            }}
            className='f-medium_20'
          >
            {selectData?.valid_from}
          </div>
        )}
      </div>
      <div
        className='apex-charts-row'
        style={{
          minWidth: '100%',
          overflow: 'hidden',
          overflowX: 'auto',
        }}
      >
        <ApexCharts
          options={state.options}
          series={state.series}
          type='bar'
          height={450}
          style={{
            minWidth: width,
          }}
        />
      </div>
    </>
  )
}

export default SummaryComponent
